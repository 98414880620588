/**
 * @file Hook to get the query params from the url in a js object format
 * @author Alwyn Tan
 */

import { useLocation } from '@reach/router'
import queryString from 'query-string'

export default () => {
  const location = useLocation()
  const queryParams = queryString.parse(location.search, {
    ignoreQueryPrefix: true,
  })
  return queryParams
}
